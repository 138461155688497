<template>
  <span>
    <b-icon-person-fill v-if="customer.usertype === 0" class="mr-1" :variant="variantWarning"
                          :id="'tt-customer-type-' + customer.id"/>
    <b-icon-briefcase-fill v-else-if="customer.usertype === 1" class="mr-1" :variant="variantWarning"
                   :id="'tt-customer-type-' + customer.id"/>
    <b-icon-question-circle v-else class="mr-1" :variant="variantWarning" :id="'tt-customer-type-' + customer.id"/>
    <b-tooltip :target="'tt-customer-type-' + customer.id" triggers="hover">{{ customerDescription }}</b-tooltip>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomerType",
  props: ['customer'],
  computed: {
    ...mapGetters(['userThemeId']),
    variantWarning() {
      if(this.userThemeId === 3) { return 'warning-deuteranopia' }
      return 'warning'
    },
    customerDescription() {
      if(this.customer.usertype === 0) {
        return 'Kunde'
      }
      else if(this.customer.usertype === 1) {
        return 'Dozent'
      }
      return 'Unbekannt'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
