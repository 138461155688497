import { render, staticRenderFns } from "./CustomerOnline.vue?vue&type=template&id=53f2a996&scoped=true&"
import script from "./CustomerOnline.vue?vue&type=script&lang=js&"
export * from "./CustomerOnline.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53f2a996",
  null
  
)

export default component.exports