<template>
  <span v-if="customer.active">
    <b-icon-arrow-up-circle-fill :variant="variantSuccess" class="mr-2" :id="'tt-status-' + customer.kdnr"/>
    <b-tooltip :target="'tt-status-' + customer.kdnr" triggers="hover">Online</b-tooltip>
  </span>
  <span v-else>
    <b-icon-arrow-down-circle-fill :variant="variantDanger" class="mr-2" :id="'tt-status-' + customer.kdnr"/>
    <b-tooltip :target="'tt-status-' + customer.kdnr" triggers="hover">Offline</b-tooltip>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomerOnline",
  props: ['customer'],
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
